import { Outlet } from "@remix-run/react";

import { SUPPORTED_LANGUAGES } from "~/config/constants";
import { LanguageContextType } from "~/context/LanguageContext";

export function loader({
  params,
}: {
  params: { locale: LanguageContextType["lang"] };
}) {
  const locale = params.locale;
  if (locale && !SUPPORTED_LANGUAGES.includes(locale)) {
    // redirect to a nice 404 page without changing the URL
    throw new Response("Not Found", { status: 404 });
  }
  // return the normal layout
  return null;
}

export default function LocaleLayout() {
  return <Outlet />;
}
